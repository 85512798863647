<template>
    <div class="reportSaleNameBill defaultReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天<br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}" @click="queryType=true" v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block" v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">餐厅:</div>
                        <div class="from-input" style="width: 180px;">
                            <el-select v-model="roomids" multiple collapse-tags @removeTag="()=>roomids=[]">
                                <el-option :value="item.Room_AutoID" :label="item.Room_Code+'-'+ item.Room_Name" v-for="item in PosRooms" :key="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block">
                    <div class="flex-box eat">
                        <div class="lable-txt">时段:</div>
                        <div class="from-input">
                            <el-select v-model="salesValue" placeholder="全天" multiple collapse-tags @removeTag="()=>salesValue=[]">
                                <el-option
                                    v-for="item in eatPhases"
                                    :key="item.EAT_PHASEID"
                                    :label="item.EAT_PHASENAME"
                                    :value="item.EAT_PHASEID">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">消费类型:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="typeAutoIDs" placeholder="请选择" :data="treeConsumeTypes" title="消费类型选择">
                            </select-tree>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">销售渠道:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="channelAutoIDs" placeholder="请选择" :data="treeOrderChannel" title="销售渠道选择">
                            </select-tree>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="search-flex">
                            <div class="bnt-search" @click="loadData()" >查询</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table headerCol2 isFooter" ref="tableBox">
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                    ref="elTable"
                    border 
                    show-summary
                    :summary-method="getSummaries"
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                    <el-table-column fixed prop="Eat_Saler" label="销售员" min-width="100" ></el-table-column>
                    <el-table-column fixed prop="Rpt_Date" label="营业日期" min-width="80" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column fixed prop="Eat_CheckIndex" label="结账单号" min-width="160" align="left"></el-table-column>
                    <el-table-column fixed prop="Eat_DeskName" label="桌号" min-width="70" align="right"></el-table-column>
                    <el-table-column prop="Eat_WaiterName" label="服务员" min-width="70" ></el-table-column>
                    <el-table-column prop="Eat_ManNum" label="人数" min-width="60" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="Eat_DeskNum" label="桌数" min-width="60" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="Consume_TypeName" label="消费类型" min-width="80" align="left" ></el-table-column>
                    <el-table-column prop="ChannelName" label="销售渠道" min-width="80" align="left" ></el-table-column>
                    <el-table-column label="销售金额">
                        <el-table-column prop="Eat_SaleMoney" label="折前" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column prop="Eat_CheckMoney" label="折后" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column prop="Eat_FactMoney" label="实收" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column prop="NoTaxFactMoney" label="去税" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
     </div>
</template>
<script>


/**销售员账单统计表 */
export default {
    name:'reportSaleNameBill',
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            userInfo:{},
            currentPage:1,
            /**表格1数据 */ 
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
           
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            //餐厅id
            roomids:[],
            //时段数据
            eatPhases:null,
            //时段
            salesValue:[],
            //消费类型数据
            consumeTypes:null,
            //消费类型id
            typeAutoIDs:[],
            //渠道数据
            orderChannel:null,
            //渠道id
            channelAutoIDs:[],
            //桌台数据
            PosRooms:[]
        }
    },
    watch:{
        data:{
            handler(){
                if(this.data){
                   this.$nextTick(()=>{
                        this.businessHours=this.data.businessHours;
                        this.queryType=this.data.queryType;
                        this.salesValue=this.data.Eat_PhaseIDs;
                        this.roomids=this.data.Eat_RoomIDs;
                        this.channelAutoIDs=this.data.ChannelIDs;
                        this.typeAutoIDs=this.data.XFTypeIDs;
                        this.loadData();
                   })
                }
            },
            immediate:true,
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.nowadays();
        
        //时段数据
        this.$cacheData.EatPhase().then((d)=>{
            if(d){
                this.eatPhases=d;
            }
        }).catch((e)=>{
            this.$message.error('加载时段数据失败：'+e.message);
            console.log('加载时段数据失败：'+e);
        })
        console.log(this.pagesize)
        //渠道数据
        this.$cacheData.OrderChannel().then((d)=>{
            if(d){
                this.orderChannel=d;
            }
        }).catch((e)=>{
            this.$message.error('加载渠道数据失败：'+e.message);
            console.log('加载渠道数据失败：'+e);
        })

        //消费类型
        this.$cacheData.ConsumeTypes().then((d)=>{
            if(d){
                this.consumeTypes=d;
            }
        }).catch((e)=>{
            this.$message.error('加载消费类型数据失败：'+e.message);
            console.log('加载消费类型数据失败：'+e);
        })
        this.$cacheData.EatRooms().then((d)=>{
            this.PosRooms=d;
        }).catch((e)=>{
            this.$alert("桌台数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
        });
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //大类数据
        foodMainCategory(){
            let foodMainCategory=[];
            this.posFoodData?.data?.map(item=>{
                let depart={
                    id:item.MainCategory_ID,
                    Name:item.MainCategory_Name,
                    children:[],
                    Hierarchy:1
                }
                if(item.FoodSubCategorys){
                    item.FoodSubCategorys.map(eat=>{
                        let chieat={
                            id:eat.SubCategory_ID,
                            Name:eat.SubCategory_Name,
                            Hierarchy:2
                        }
                        depart.children.push(chieat)
                    })
                }
                foodMainCategory.push(depart)
            })
            return foodMainCategory;
        },
        //消费类型数据
        treeConsumeTypes(){
            let treeData=[];
            this.consumeTypes?.map((group)=>{
                let json={
                    id:group.ConsumeTypeKind_AutoID,
                    name:group.ConsumeTypeKind_Code+group.ConsumeTypeKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.ConsumeTypeList){
                    group.ConsumeTypeList.map(item=>{
                        let children= Object.assign({
                            id:item.ConsumeType_AutoID,
                            name:item.ConsumeType_Code+'-'+item.ConsumeType_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        },
        //渠道数据
        treeOrderChannel(){
            let treeData=[];
            this.orderChannel?.map((group)=>{
                let json={
                    id:group.OrderChannelKind_AutoID,
                    name:group.OrderChannelKind_Code+group.OrderChannelKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.OrderChannelList){
                    group.OrderChannelList.map(item=>{
                        let children= Object.assign({
                            id:item.OrderChannel_AutoID,
                            name:item.OrderChannel_Code+'-'+item.OrderChannel_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        },
        //合计
        total(){
            let total={
                Eat_ManNum:0,
                Eat_DeskNum:0,
                Eat_SaleMoney:0,
                Eat_CheckMoney:0,
                Eat_FactMoney:0,
                NoTaxFactMoney:0,
            };
            (this.tableList||[]).forEach(it=>{
                total.Eat_ManNum+=it.Eat_ManNum;
                total.Eat_DeskNum+=it.Eat_DeskNum;
                total.Eat_SaleMoney+=it.Eat_SaleMoney;
                total.Eat_CheckMoney+=it.Eat_CheckMoney;
                total.Eat_FactMoney+=it.Eat_FactMoney;
                total.NoTaxFactMoney+=it.NoTaxFactMoney;
            })
            return total;
        }
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.$refs.elTable){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable,
                    titleName:"销售员账单统计表",
                    list:this.tableList
                })
            }
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        getSummaries({ columns, data }){
            const sums= [];
            columns.forEach((column, index) => {
                if(this.total[column.property]!=undefined){
                    sums[index]=this.priceFormat(null,null,this.total[column.property]);
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**查询 */
        loadData(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                Eat_PhaseIDs:this.salesValue,
                Eat_RoomIDs:this.roomids,
                ChannelIDs:this.channelAutoIDs,
                XFTypeIDs:this.typeAutoIDs,
                SalerName:this.data.SalerName,
                IsHideMarkedBills:this.$global.isLableData //是否隐藏带标签的订单
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.SalerPerfConsumeReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        }
    }
}
</script>

<style lang="scss" >
@import './reportSaleNameBill.scss'
</style>